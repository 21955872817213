<template>
  <!--<PageHeader :title="title" :items="items" />-->
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div></div>
        <div class="col-12 mt-2">
          <div class="card-header cardHeader-bg" style="padding: 14px;">
            <router-link to="/kitchens">
              <button class="btn btn-info rounded-pill mb-2">
                Back
              </button>
            </router-link>
            <h4 class="card-title mb-0">{{ headerTitle }}</h4>
            <br />
            <div
              v-if="alert"
              role="alert"
              aria-live="polite"
              aria-atomic="true"
              class="alert alert-danger"
            >
              {{ alertText }}
            </div>
          </div>
        </div>
        <div class="card-body">
          <div>
            <!-- <b-form @submit="onSubmit" @reset="onReset" v-if="show"> -->
            <b-form-group
              id="input-group-3"
              label="Restaurant:"
              label-for="input-3"
            >
              <multiselect
                track-by="id"
                :custom-label="customLabel"
                label="username"
                v-model="selected"
                :options="users"
                placeholder="Select Restaurant"
                @select="onSelect"
              >
                <template slot="singleLabel" slot-scope="{ option }"
                  ><strong
                    >{{ option.name }} - ({{ option.street }} {{ option.city }}
                    {{ option.state }})</strong
                  ></template
                >
              </multiselect>
            </b-form-group>

            <div v-if="showDiv">
              <div>
                <div
                  style="display:flex;justify-content:space-between;align-items:center;"
                >
                  <div style="width:40%">
                    <label style="font-weight: bold;margin-right: 10px;">
                      Restaurant Name: </label
                    ><span>{{ this.resturantName }}</span>
                  </div>
                  <div style="width:60%">
                    <label style="font-weight: bold;margin-right: 10px;">
                      ID: </label
                    ><span> {{ this.resturantId }}</span>
                  </div>
                </div>
                <br />

                <div
                  style="display:flex;justify-content:space-between;align-items:center;"
                >
                  <div style="width:40%">
                    <label style="font-weight: bold;margin-right: 10px;">
                      Address: </label
                    ><span>{{ this.location }}</span>
                  </div>
                  <div style="width:40%">
                    <label style="font-weight: bold;margin-right: 10px;">
                      Type: </label
                    ><span> {{ this.type }}</span>
                  </div>
                  <!-- <div style="width:20%;padding-left:10px">
                      <a class="btn btn-info" @click="appendRow"
                        >Delivery Address</a
                      >
                    </div> -->
                </div>
              </div>
              <br />

              <div>
                <hr />
                <div
                  style="display:flex;justify-content:space-between;align-items:center;"
                >
                  <div style="width:50%">
                    <label style="font-weight: bold;margin-right: 10px;">
                      User Name: </label
                    ><span>{{ this.kitcheUserName }}</span>
                  </div>
                  <div style="width:50%">
                    <label style="font-weight: bold;margin-right: 10px;">
                      Email: </label
                    ><span> {{ this.kitcheUserEmail }}</span>
                  </div>

                  <div style="width:24%;padding-left:10px">
                    <b-button class="btn-info" @click="KitchenDetailModal()">{{
                      this.buttonName
                    }}</b-button>
                  </div>
                </div>
                <br />
              </div>
            </div>

            <br /><br />

            <!-- <b-button ref="save" type="submit" class="btn-info"
                >Save</b-button
              > -->
            <!-- </b-form> -->
          </div>
        </div>
      </div>
      <br /><br />
      <template v-if="isShowModel">
        <VerficationModal
          :show="showModal"
          :userObj="userObj"
          :actionType="actionType"
          @deleteImageRecord="deleteImageRecord"
          @deleteDeliveryAddress="deleteDeliveryAddress"
          @closeModal="closeModal"
        />
      </template>
      <template>
        <OrderViewTable
          v-if="orderViewTable"
          :loans="loans"
          @showBulkPopupDeliveryAddress="showBulkPopupDeliveryAddress"
        />
      </template>
      <template v-if="showKitchenDetailModal">
        <KitchenDetail
          @closeModal="closeModal"
          @ChangeKitchenCredential="ChangeKitchenCredential"
          :email="email"
          :username="username"
          :kitcheUserId="kitcheUserId"
          :restaurantId="restaurantId"
        />
      </template>
      <br /><br />

      <br />
    </div>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
// import { appendRow, deleteRow } from "@/Helper/helpers.js";
import Vue from "vue";

import VerficationModal from "./VerficationModal";
import KitchenDetail from "./KitchenDetail";
import OrderViewTable from "@/view/pages/Kitchen/OrderViewTable";
import Multiselect from "vue-multiselect";
import axios from "axios";
export default {
  components: {
    Multiselect,
    KitchenDetail,
    VerficationModal,
    OrderViewTable,
  },
  data() {
    return {
      current_address: [],
      deliveryAddressArray: [],
      tempDeletedDeliveryAddress: [],
      deliveryAddresstempArray: [],
      headerTitle: "Add Kitchen",
      buttonName: "Add",
      resturantName: "",
      resturantId: "",
      password: "",
      location: "",
      kitcheUserName: "",
      kitcheUserId: "",
      kitcheUserEmail: "",
      type: "",
      size: null,
      selected: null,
      user: null,
      users: [],
      restaur: false,
      showKitchenDetailModal: false,
      changeClasss: false,
      showDiv: false,
      showModal: false,
      imagesArray: [],
      loans: [],
      profileimageUrl: null,
      profileImage: null,
      isShowModel: false,
      alertText: "",
      alert: false,
      showImagesViewTable: false,
      orderViewTable: false,
      price: "",
      show: true,
      apiUrl: "/customer/register",
    };
  },

  mounted() {
    document.title = "Add Kitchen";

    this.getRestaurants();
  },

  methods: {
    KitchenDetailModal() {
      this.showKitchenDetailModal = true;
      this.email = this.kitcheUserEmail;
      this.username = this.kitcheUserName;
    },
    // async doApi(query) {
    //   if (query.length > 2) {
    //     console.log(query);
    //   }
    // },
    ChangeKitchenCredential(object) {
      if (object.code == 200) {
        this.kitcheUserName = object.data.username;
        this.kitcheUserEmail = object.data.email;
        this.alert = true;
        this.alertText = object.message;
        this.changeClasss = true;
      } else {
        this.alert = true;
        this.alertText = object.message;
        this.changeClasss = false;
      }
    },
    closeModal(value) {
      this.isShowModel = value;
      this.showKitchenDetailModal = value;
    },
    editDataApppend() {
      this.editcustomer = JSON.parse(localStorage.getItem("editcustomer"));
      if (this.editcustomer.profile_image) {
        this.profileimageUrl = this.editcustomer.profile_image;
        this.profileImage = this.editcustomer.profile_image;
      }
      this.name = this.editcustomer.username;
      this.id = this.editcustomer.id;
      this.phone_number = this.editcustomer.phone_number;
      this.email = this.editcustomer.email;
      this.deliveryAddressArray = this.editcustomer.addresses;
      this.deliveryAddressView = true;
    },

    deliveryAddress: function(addressData, placeResultData, id) {
      var parts = id.split("_");
      let data = {
        postcode: addressData.postal_code,
        street: addressData.route,
        city: addressData.locality,
        state: addressData.administrative_area_level_1,
        lat: addressData.latitude,
        lng: addressData.longitude,
      };
      this.deliveryAddresstempArray.splice(parts[1], 1);
      this.deliveryAddresstempArray.push(data);
    },
    appendRow() {
      this.deliveryAddressArray.push(this.deliveryAddresstempArray[0]);
      this.$refs.autocomplete.clear();
      this.toasterMessage();
      if (this.deliveryAddressArray.length > 0) {
        this.deliveryAddressView = true;
      }
    },

    toasterMessage() {
      Vue.$toast.success("Delivery Address Save Please Check Blow", {
        position: "top-right",
        duration: 5000,
      });
    },

    deleteData(object, actioType) {
      this.showModal = true;
      this.isShowModel = true;
      this.userObj = object;
      this.actionType = actioType;
    },

    deleteImageRecord(object) {
      let index = this.loans.findIndex((x) => x.id === object.item.id);
      if (index !== -1) {
        this.editRestaurant.images.splice(index, 1);
        this.loans = this.editRestaurant.images;
        localStorage.removeItem("editRestaurant");
        localStorage.setItem(
          "editRestaurant",
          JSON.stringify(this.editRestaurant)
        );
      }

      this.alert = true;
      this.alertText = "Record Deleted Successfully";
      this.hideAlert();
    },

    showBulkPopupDeliveryAddress(object, actionType) {
      this.showModal = true;
      this.isShowModel = true;
      this.userObj = object;
      this.actionType = actionType;
    },

    deleteDeliveryAddress(object) {
      this.tempArrayDelte.push(object.item.id);
      let index = this.deliveryAddressArray.findIndex(
        (x) => x.lat === object.item.lat
      );
      this.deliveryAddressArray.splice(index, 1);
      this.alert = true;
      this.alertText = "Record Deleted Successfully";
      this.hideAlert();
    },

    hideAlert() {
      setTimeout(() => (this.alert = false), 2000);
    },
    CurrentAddress: function(addressData) {
      let data = {
        postcode: addressData.postal_code,
        street: addressData.route,
        city: addressData.locality,
        state: addressData.administrative_area_level_1,
        lat: addressData.latitude,
        lng: addressData.longitude,
      };
      this.current_address = data;
    },
    customLabel({ name, street, city, state }) {
      return `${name} — (${street} ${city} ${state})`;
    },

    onSelect(option) {
      this.restaurantId = option.id;
      if (option.kitchen != null) {
        this.buttonName = "Edit";
        this.kitcheUserName = option.kitchen.username;
        this.kitcheUserId = option.kitchen.id;
        this.kitcheUserEmail = option.kitchen.email;
      } else {
        this.buttonName = "Add";
        this.kitcheUserId = "";
        this.kitcheUserName = "";
        this.kitcheUserId = "";
        this.kitcheUserEmail = "";
      }
      this.orderViewTable = true;
      this.showDiv = true;
      this.loans = option.orders;
      this.resturantName = option.name;
      this.resturantId = option.id;
      this.location = option.street + " " + option.city + " " + option.state;
      this.type = option.type;
    },

    onSubmit(evt) {
      try {
        const formData = new FormData();
        // if (this.selected == null) {
        //   // formData.append("customer_id", this.selected.id);
        //   formData.append("customer_id", 1);
        evt.preventDefault();
        //   this.alert = true;
        //   this.alertText = "Customer is Required";
        //   return false;
        // }
        formData.append("id", this.id);
        this.addLoaderClasses("save");
        formData.append("username", this.name);
        formData.append("email", this.email);
        formData.append("phone_number", this.phone_number);
        formData.append("password", this.password);
        formData.append(
          "tempDeletedDeliveryAddress",
          this.tempDeletedDeliveryAddress
        );
        formData.append("addresses", JSON.stringify(this.deliveryAddressArray));
        if (this.profileImage) {
          formData.append("profile_image", this.profileImage);
        }

        const headers = {
          Authorization: "Bearer " + localStorage.getItem("id_token"),
        };
        axios
          .post(this.$path + this.apiUrl, formData, {
            headers,
          })
          .then((response) => {
            if (response.data.code == 200) {
              this.$router.push({ name: "customer" });
            }
            if (response.data.code == 422) {
              this.alert = true;
              this.alertText = response.data.message;
              this.removeLoaderClasses("save");
            }

            if (response.data.code == 501) {
              this.$router.push({ name: "login" });
            }
          })
          .catch(({ response }) => {
            if (response) {
              alert("Technical Issue");
            }
          });
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
      }
    },

    addLoaderClasses(ref) {
      const submitButton = this.$refs[ref];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
    },

    removeLoaderClasses(ref) {
      const submitButton = this.$refs[ref];
      submitButton.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
    },

    getLogo(logo) {
      return this.$IMAGE_BASE_URL + logo;
    },

    profileImageShow(e) {
      const file = e.target.files[0];
      this.profileImage = file;
      this.profileimageUrl = URL.createObjectURL(file);
    },

    getRestaurants() {
      const headers = {
        Authorization: "Bearer " + localStorage.getItem("id_token"),
      };
      try {
        axios
          .post(this.$path + "/restaurant/search-restaurant", "", {
            headers,
          })
          .then((response) => {
            this.users = response.data.data;
          });
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
      }
    },

    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form.email = "";
      this.form.name = "";
      this.form.food = null;
      this.form.checked = [];
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
  },
};
</script>
<style scoped>
#add-loan-btn {
  padding: 8px 25px 8px 25px;
}
.multiselect__single {
  font-size: 12px;
}
.multiselect * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 13px;
}

.multiselect__option--highlight:after {
  background-color: #428bca !important;
}

.alert {
  padding: 0.3rem 0.5rem;
}
.btn-info {
  background-color: #5cb700;
  border-color: #5cb700;
}
.btn-info:hover {
  border-color: #5cb700 !important;
  background-color: #5cb700 !important;
}

.btn-info:active {
  border-color: #5cb700 !important;
  background-color: #5cb700 !important;
}

.btn-info:focus {
  border-color: #5cb700 !important;
  background-color: #5cb700 !important;
}
</style>
